import React, { useContext, useEffect, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonDatetimeButton,
  IonDatetime,
  IonModal,
  IonLoading,
  IonToast,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  useIonAlert,
  IonText,
  IonIcon
} from "@ionic/react";
import {
  Craft,
  Employee,
  getAllFields,
  LaborResults,
  LaborsMetadata,
  Rate,
} from "../models/workorders/LaborActivity";
import { decodeParam } from "../util/ApiHelper";
import { useParams } from "react-router";
import FilterModal from "../components/FilterModal";
import moment from "moment";
import OnlineStatus from "../components/OnlineStatus";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";
import { getColumnsWithGroup } from "../api/Windows";
import { getLaborsByWorkOrder, postLabor, putLabor } from "../api/Labors";
import { getCrafts, getEmployeesLiteList } from "../api/Employees";
import { getValidationCodes } from "../api/ValidationCodes";
import {
  getCraftCodeAccess,
  getEmployeesAccess,
  getUserId,
} from "../util/UserHelper";
import { ApiResponse } from "../models/api/ApiResponse";
import { getValueSearchDetails } from "../api/QueryDesigner";
import { searchOutline, searchSharp } from "ionicons/icons";

interface TimeEntryModalProps {
  isOpen: boolean;
  cancel: () => void;
  confirm: (timeEntry: LaborResults[]) => void;
  finalTime?: number;
  objectID?: string;
  pathId?: string;
}

const TimeEntry: React.FC<TimeEntryModalProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  const [presentAlert] = useIonAlert();
  const [orgLaborLog, setOrgLaborLog] = useState<LaborResults[]>([]);
  let [currentEmployee, setCurrentEmployee] = useState<string>("");
  const [loggedInEmployee, setLoggedInEmployee] = useState<string>("");
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [hasEmployeeAccess, setHasEmployeeAccess] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [laborLogErrorState, setLaborLogErrorState] = useState(false);
  const [metadata, setMetadata] = useState<LaborsMetadata>();
  const [metadataErrorState, setMetadataErrorState] = useState(false);
  const [craftList, setCraftList] = useState<Craft[]>([]);
  const [craftListErrorState, setCraftListErrorState] = useState(false);
  const [rateList, setRateList] = useState<Rate[]>([]);
  const [rateListErrorState, setRateListErrorState] = useState(false);
  const [currentDate, setCurrentDate] = useState<string>();
  const [currentCraft, setCurrentCraft] = useState("");
  const [occurrence, setOccurrence] = useState<number>();
  const [hours, setHours] = useState<number>();
  const [currentRate, setCurrentRate] = useState("");
  const [userComments, setUserComments] = useState<string>("");
  const [timeEntry, setTimeEntry] = useState<LaborResults[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [woBase, setWoBase] = useState('');
  const { isOpen, cancel, confirm, finalTime, objectID, pathId } = props;
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterModalColumnName, setFilterModalColumnName] = useState("");
  const [filterModalModuleName, setFilterModalModuleName] = useState("");
  const dateToday = Date.now();

  const getEmployee = async () => {
    return await getUserId().then((response) => {
      if (response !== undefined) {
        setLoggedInEmployee(response!);
      }
    });
  };

  const onCancel = () => {
    cancel();
    if (objectID) {
      getLaborLog();
    } else {
      if (finalTime) {
        setHours(finalTime);
      } else {
        setHours(undefined);
      }
      resetInputs();
    }
  };

  const resetInputs = async () => {
    setCurrentEmployee(loggedInEmployee);
    getCraftList(loggedInEmployee);
    getRateList();
    setOccurrence(undefined);
    setUserComments("");
    setWoBase("");
  };

  const metadataQuery = async () => {
    setShowLoading(true);
    const data = {
      IncludeValidValues: "true",
      IncludeValidValuesExceptions: ["Equipment"],
      ValidValueFilters: [],
      WindowName: "mob_wo_labor",
      ContextPKey: {
        Plant: await getCurrentPlant(),
        WoBase: "",
        WoTask: "  ",
        WoSubtask: "  ",
      },
    };
    getColumnsWithGroup(data).then((response) => {
      if (response.status !== 200) {
        setMetadataErrorState(true);
      } else {
        if (pathId !== "FromTimeEntryMenu") {
          let data = response.data;
          delete data.fields['wo_log_labor.wo_base'];
        }
        setMetadata(response.data);
      }
      setShowLoading(false);
    });
  };

  const checkUserAccess = async () => {
    if (await getEmployeesAccess()) {
      getAllEmployeeIds();
      setHasEmployeeAccess(true);
    } else {
      setHasEmployeeAccess(false);
    }
  };

  const getAllEmployeeIds = async () => {
    const data = {
      PropertyList: ["EmployeeName", "EmployeeId"],
      Filter: "",
    };
    getEmployeesLiteList(data).then((response) => {
      setEmployeeList(response.data);
    });
  };

  const handleSelectedEmployee = async (employee: string) => {
    setCurrentEmployee(employee);
    getCraftList(employee);
  };

  const getLaborLog = async () => {
    await getLaborsByWorkOrder({
      woNumber: displayWo,
      appName: "Mobile",
      translations: true,
    }).then((response) => {
      if (response.status !== 200) {
        setLaborLogErrorState(true);
      } else {
        let correctLog = response.data.find(
          (log: { ObjectId: string | undefined }) => {
            return log.ObjectId === objectID;
          }
        );
        setOrgLaborLog(correctLog);
        setCurrentDate(correctLog.WorkDate);
        handleSelectedEmployee(correctLog.EmployeeId);
        getRateList();
        setCurrentCraft(correctLog.ResourceId);
        setCurrentRate(correctLog.RateName);
        setOccurrence(correctLog.Occurrence);
        setHours(correctLog.WorkHours);
        setUserComments(correctLog.Comments);
      }
    });
  };

  const getCraftList = async (employee: string) => {
    getCrafts(employee).then((response) => {
      if (response.status !== 200) {
        setCraftListErrorState(true);
      } else {
        checkEmptyCrafts(response);
        setCraftList(response.data?.Crafts);
        if (!objectID) setCurrentCraft(response.data?.PrimaryCraftCode);
      }
    });
  };

  const checkEmptyCrafts = async (response: ApiResponse) => {
    if ((await getCraftCodeAccess()) && response.data.Crafts?.length === 0) {
      return getCrafts("").then((response) => {
        setCraftList(response.data?.Crafts);
        if (!objectID) setCurrentCraft(response.data?.PrimaryCraftCode);
      });
    }
  };

  const getRateList = async () => {
    getValidationCodes("Entity__RateMultiplier").then((response) => {
      if (response.status !== 200) {
        setRateListErrorState(true);
      } else {
        if (!objectID) {
          let splitRate = response.data[0]?.Description.split(" ");
          let rateDescription = splitRate[splitRate.length - 1];
          setCurrentRate(rateDescription);
        }
        setRateList(response.data);
      }
    });
  };

  const setupTimeEntryDetails = async () => {
    Promise.all([getEmployee(), checkUserAccess()]).finally(() => {
      metadataQuery().then(() => {
        if (objectID) {
          getLaborLog();
        } else {
          setCurrentDate(moment(dateToday).format("YYYY-MM-DDTHH:mm:ssZ"));
          loggedInEmployee && resetInputs();
          if (finalTime) {
            setHours(finalTime);
          } else {
            setHours(undefined);
          }
        }
      });
    });
  };

  async function saveTimeEntry() {
    const labors: LaborResults[] = [];
    if(displayWo === '') {
      displayWo = woBase;
    }
    if(displayWo === '') {
      presentAlert({
        header: "Error",
        message: translatedMessages["EnterValidWorkOrder"]?.MessageText || "Please enter a valid Work Order.",
        buttons: [
          {
            text: translations["lbl_btn_ok"] || "OK",
          },
        ],
      });
      return;
    }
    let multiplierVal = 1;
    const currentRateItem = rateList.find(item => {
      const descriptionParts = item.Description.split(' - ');
      return descriptionParts[1] === currentRate;
    });
    if (currentRateItem) {
      multiplierVal = Number.parseFloat(currentRateItem.Code);
    }
    if (objectID) {
      const data = {
        RequestObject: {
          ...orgLaborLog,
          WorkDate: moment(currentDate).format("MM/DD/YYYY"),
          WorkHours: hours,
          ResourceId: currentCraft,
          RateName: currentRate,
          Occurrence: occurrence,
          EnteredBy: loggedInEmployee,
          Comments: userComments,
          EmployeeId: currentEmployee,
          CfWorkOrder: displayWo,
          Multiplier: multiplierVal,
        },
        Confirmation: {},
      };

      putLabor(data).then((response) => {
        labors.push(data.RequestObject);
        setTimeEntry(labors);
        if (response.data.Success) {
          // Display Success modal & then redirect
          presentAlert({
            header: `${translatedMessages["ActivitySaveSuccess"]?.MessageText ||
              "Log Activity Saved Successfully."
              }`,
            buttons: [
              {
                text: translations["lbl_btn_ok"] || "OK",
                handler: () => {
                  confirm(timeEntry);
                },
              },
            ],
          });
        } else {
          // Display failure modal and stay on the page
          presentAlert({
            header: "Editing failed",
            buttons: [
              {
                text: translations["lbl_btn_ok"] || "OK",
              },
            ],
          });
        }
      });
    } else {
      const data = {
        RequestObject: {
          WorkDate: moment(currentDate).format("MM/DD/YYYY"),
          WorkHours: hours,
          ResourceId: currentCraft,
          Multiplier: multiplierVal,
          WorkOrderNumber: displayWo,
          Occurrence: occurrence,
          Comments: userComments,
          EmployeeId: currentEmployee,
          RateName: currentRate,
        },
        Confirmation: {},
      };

      postLabor(data).then((response) => {
        labors.push(data.RequestObject);
        setTimeEntry(labors);
        if (response.data.Success !== true) {
          setShowErrorToast(true);
          setErrorMessage(response.data?.MessageList[0]?.Text);
        } else {
          confirm(timeEntry);
          resetInputs();
          if (finalTime) {
            setHours(finalTime);
          } else {
            setHours(undefined);
          }
        }
      });
    }
  }
  const resetSearchState = () => {
    setFilterModalColumnName('');
    setFilterModalModuleName('');
  };

  const onFilterModalConfirm = (selectedItem: string | number | null | undefined) => {
    setParameter(filterModalColumnName, selectedItem);
    setIsFilterModalOpen(false);
    resetSearchState();
  };

  const setParameter = (
    paramKey: string,
    paramValue: string | number | null | undefined
  ) => {
    if (paramKey === 'cf_work_order') {
      setWoBase(paramValue as string);
    }
    else if(paramKey === 'employee_id') {
      setCurrentEmployee(paramValue as string);
    }
  };

  useEffect(() => {
    setupTimeEntryDetails();
  }, [loggedInEmployee, finalTime, objectID]);

  const errorDisplay = () => {
    let errorState = "";
    switch (true) {
      case metadataErrorState:
        errorState =
          translatedMessages["ErrFetchingLabor"]?.MessageText ||
          "Error fetching the labor activity fields";
        break;
      case craftListErrorState:
        errorState =
          translatedMessages["ErrFetchingCraftList"]?.MessageText ||
          "Error fetching the craft list";
        break;
      case rateListErrorState:
        errorState =
          translatedMessages["ErrFetchingTheRateList"]?.MessageText ||
          "Error fetching the rate list";
        break;
      case laborLogErrorState || (objectID && !orgLaborLog && !showLoading):
        errorState =
          translatedMessages["ErrorReadingTimeEntries"]?.MessageText ||
          "Error reading time entries";
        break;
      default:
        break;
    }
    if (errorState !== "") {
      return <IonText color="danger">{errorState}</IonText>;
    }
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => setShowErrorToast(false)}
        message={errorMessage}
        duration={3000}
        position="top"
      />
      <FilterModal
        isOpen={isFilterModalOpen}
        moduleName={filterModalModuleName}
        cancel={() => setIsFilterModalOpen(false)}
        confirm={onFilterModalConfirm}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          {!!objectID && !!orgLaborLog ? (
            <IonTitle>
              {translatedMessages["EditTimeEntry"]?.MessageText ||
                "Edit Time Entry"}
            </IonTitle>
          ) : (
            <IonTitle>
              {pathId === "FromTimeEntryMenu"
                ? translations["lbl_time_entry"] || "Time Entry"
                : `${displayWo} - ${translations["lbl_time_entry"] || "Time Entry"}`}
            </IonTitle>
          )}
          <IonButtons slot="end">
            <IonButton disabled={!hours} onClick={() => saveTimeEntry()}>
              {translations["lbl_btn_save"] || "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <OnlineStatus />
      </IonHeader>
      <IonContent fullscreen>
        {!!metadata && (
          <IonList lines="full">
            {getAllFields(metadata).map((field) => {
              return (
                <IonItem key={field.IdText}>
                  <IonLabel>{field.TranslatedIdText}</IonLabel>

                  {field.PropertyName === "WoBase" && pathId === 'FromTimeEntryMenu' ? (
                    <>
                      <IonInput
                        class="ion-text-end"
                        value={woBase}
                        onIonInput={(ev) => onFilterModalConfirm(ev.target.value)}
                      ></IonInput>
                      <IonIcon
                        slot="end"
                        ios={searchOutline}
                        md={searchSharp}
                        onClick={async () => {
                          resetSearchState();
                          setFilterModalColumnName('cf_work_order');
                          const response: ApiResponse = await getValueSearchDetails(
                            {
                              tableName: 'wo_work_order',
                              columnName: 'cf_work_order',
                            }
                          );
                          if (response.status === 200) {
                            setFilterModalModuleName(response.data.QueryWizardModuleName);
                            setIsFilterModalOpen(true);
                          }
                        }}
                      ></IonIcon>
                    </>
                  ) : field.PropertyName === "WorkDate" ? (
                    <>
                      <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                      <IonModal keepContentsMounted={true}>
                        <IonDatetime
                          id="datetime"
                          presentation="date"
                          value={currentDate}
                          onIonChange={(ev) =>
                            setCurrentDate(String(ev.detail.value))
                          }
                        ></IonDatetime>
                      </IonModal>
                    </>
                  ) : field.PropertyName === "EmployeeId" ? (
                    field.ElementType === "Dropdown" ? (
                      <IonSelect
                        slot="end"
                        interface="alert"
                        placeholder={translations["lbl_btn_select"] || "Select"}
                        value={currentEmployee}
                        disabled={!!objectID}
                        onIonChange={(ev) => handleSelectedEmployee(ev.detail.value)}
                      >
                        {hasEmployeeAccess &&
                          employeeList.map((employee, index) => {
                            return (
                              <IonSelectOption key={`${index}-${employee}`}>
                                {employee.EmployeeId}
                              </IonSelectOption>
                            );
                          })}
                        {!hasEmployeeAccess && (
                          <IonSelectOption>{currentEmployee}</IonSelectOption>
                        )}
                      </IonSelect>
                    ) : field.ElementType === "ISS" ? (
                      <>
                        <IonInput
                          class="ion-text-end"
                          value={currentEmployee}
                          disabled={!!objectID}
                          onIonInput={(ev) => onFilterModalConfirm(ev.target.value)}
                        ></IonInput>
                        <IonIcon
                          slot="end"
                          ios={searchOutline}
                          md={searchSharp}
                          onClick={async () => {
                            resetSearchState();
                            setFilterModalColumnName('employee_id');
                            const response: ApiResponse = await getValueSearchDetails(
                              {
                                tableName: 'em_employee',
                                columnName: 'employee_id',
                              }
                            );
                            if (response.status === 200) {
                              setFilterModalModuleName(response.data.QueryWizardModuleName);
                              setIsFilterModalOpen(true);
                            }
                          }}
                        ></IonIcon>
                      </>
                    ) : null
                  ) : field.PropertyName === "ResourceId" ? (
                    <IonSelect
                      slot="end"
                      interface="popover"
                      placeholder={translations["lbl_btn_select"] || "Select"}
                      value={currentCraft}
                      disabled={!!objectID}
                      onIonChange={(ev) => setCurrentCraft(ev.detail.value)}
                    >
                      {craftList.map((craft, index) => {
                        return (
                          <IonSelectOption key={`${index}-${craft}`}>
                            {craft.Code}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                  ) : field.PropertyName === "Occurrence" ? (
                    <IonInput
                      class="ion-text-end"
                      inputMode="decimal"
                      value={occurrence}
                      required
                      type="number"
                      onIonInput={(ev) =>
                        setOccurrence(Number(ev.target.value))
                      }
                    ></IonInput>
                  ) : field.PropertyName === "WorkHours" ? (
                    <IonInput
                      class="ion-text-end"
                      value={hours}
                      required
                      type="number"
                      onIonInput={(ev) => {
                        setHours(Number(ev.target.value));
                      }}
                    ></IonInput>
                  ) : field.PropertyName === "RateName" ? (
                    <IonSelect
                      slot="end"
                      interface="popover"
                      placeholder={translations["lbl_btn_select"] || "Select"}
                      value={currentRate}
                      onIonChange={(ev) => setCurrentRate(ev.detail.value)}
                    >
                      {rateList.map((rate, index) => {
                        let splitRate = rate.Description.split(" ");
                        let rateDescription = splitRate[splitRate.length - 1];
                        return (
                          <IonSelectOption key={`${index}-${rate}`}>
                            {rateDescription}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                  ) : field.PropertyName === "Comments" ? (
                    <IonInput
                      class="ion-text-end"
                      value={userComments}
                      onIonInput={(ev) => {
                        setUserComments(String(ev.target.value));
                      }}
                    ></IonInput>
                  ) : (
                    <IonInput class="ion-text-end"></IonInput>
                  )}
                </IonItem>
              );
            })}
          </IonList>
        )}
        {objectID && !orgLaborLog && !showLoading ? errorDisplay() : <></>}
      </IonContent>
    </IonModal>
  );
};

export default withAITracking(reactPlugin, TimeEntry, "TimeEntry");
